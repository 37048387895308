import axios from 'axios';
import * as contentful from 'contentful';
import { useBrandDetails } from 'dw-au-react-components';
import React, {
	createContext,
	useEffect,
	useMemo,
	useState,
	useCallback
} from 'react';

export const ProductCarouselContext = createContext();

export const ProductCarouselProvider = ({ children, sectionName, slug }) => {
	const brandData = useBrandDetails();

	const isPreview = new URL(window.location.href).searchParams.get('preview')
		? true
		: false;

	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [itemCodes, setItemCode] = useState([]);
	const [sectionTitle, setSectionTitle] = useState(undefined);
	const [section, setSection] = useState(undefined);
	const [description, setDescription] = useState(undefined);

	const client = contentful.createClient({
		space: '759ah7skns84',
		...(isPreview && { host: 'preview.contentful.com' }),
		accessToken: isPreview
			? 'lo_AIhv7unvx5CBSv8cMWMtugozF1y7_aL8S_M-9Aqw'
			: 'WRSJ8s9akgwwr-ejARj8VUrKv67gUu8NhjnhYZu35-0'
	});

	const sectionName1 = useMemo(() => {
		if (sectionName) {
			return sectionName;
		}
		const pathname = location.pathname;
		if (pathname === '' || pathname === '/') {
			return 'Homepage';
		}
		return 'Unlimited';
	}, [sectionName]);

	const params = {
		content_type: 'productCarousel'
	};
	params['fields.brand'] =
		location.hostname.includes('velocitywinestore.com.au') ||
		location.hostname.includes('redemption.virginwines')
			? 'vws'
			: brandData.tag;
	params['fields.section'] = sectionName1;
	if (sectionName === 'Blog') {
		params['fields.blogPath'] = slug;
	}

	const fetchContentfulData = useCallback(() => {
		setLoading(true);

		client
			.getEntries(params)
			.then((response) => {
				response.items.map((item) => {
					if (item?.fields?.products) {
						getProducts(item?.fields?.products);
						setItemCode(item?.fields?.products);
						setSectionTitle(item.fields.displayTitle || '');
						setSection(item.fields.section);
						setDescription(item.fields.description);
					}
				});
				if (response?.items?.length === 0) {
					setLoading(false);
				}
			})
			.catch(() => {
				setLoading(false);
			});
	}, [client, params]);

	useEffect(() => {
		fetchContentfulData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Get Products data
	 * @param {*} ItemsList
	 */
	const getProducts = (ItemsList) => {
		let productsList = [];
		if (ItemsList.length > 0) {
			setLoading(true);
			const createRequest = (itemCode) =>
				axios.get(`/api/product/item/${itemCode}`).catch((err) => ({ err }));
			const requests = ItemsList.map(createRequest);

			// Promise.allSettled(requests)
			// 	.then((results) => {
			// 		results.forEach((result) => {
			// 			if (result.status === 'fulfilled') {
			// 				console.log('here.....', result?.value);
			// 				if (result?.value?.data?.response) {
			// 					console.log('here.....');
			// 					productsList.push(response?.data?.response);
			// 				}
			// 			}
			// 		});
			// 		setLoading(false);
			// 		setProducts(productsList);
			// 	})
			// 	.catch((err) => {
			// 		setLoading(false);
			// 	});

			axios
				.all(requests)
				.then(
					axios.spread((...responses) => {
						responses.forEach((response) => {
							if (response?.data?.response) {
								productsList.push(response?.data?.response);
							}
						});
						setLoading(false);
						setProducts(productsList);
					})
				)
				.catch(() => {
					setLoading(false);
				});
		}
	};

	return (
		<ProductCarouselContext.Provider
			value={{
				products,
				itemCodes,
				sectionTitle,
				section,
				description
			}}
		>
			{loading ? (
				<div className='loader-new w-100 d-flex justify-content-center align-items-center'>
					<svg
						className='loader-new__inner'
						viewBox='0 0 66 66'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle
							className='loader-new__circle'
							fill='none'
							cx='33'
							cy='33'
							r='30'
						></circle>
					</svg>
				</div>
			) : products && products.length > 0 ? (
				children
			) : null}
		</ProductCarouselContext.Provider>
	);
};

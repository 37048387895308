import styled from '@emotion/styled';
import { useBrandDetails } from 'dw-au-react-components';
import React, { useContext, useRef } from 'react';
import Slider from 'react-slick';
import ProductImage from '../components/ProductImage';
import { ProductCarouselContext } from '../context/ProductCarouselProvider';
import ProductPrice from '../components/ProductPrice';
import AddtoCartProduct from '../components/AddToCart';

const StyledMain = styled.div`
	margin: 0 40px 0 20px;
	@media (min-width: 1200px) {
		margin: 0;
	}
	@media (max-width: 1199px) {
		margin: 0 40px 0 20px;
	}
	@media (max-width: 450px) {
		margin: 0 0 50px 0;
	}
	.slick-dots {
		bottom: -40px;
	}
	@media (min-width: 1100px) {
		.slick-initialized .slick-slide {
			width: ${({ products }) =>
				products.length < 4 ? 'auto !important' : '100%'};
		}
	}
`;

const StyledLayout = styled.div`
	padding: 0 20px;
	width: auto !important;
	@media (max-width: 579px) {
		width: 100% !important;
	}
	.custome-slick {
		position: relative;
	}
	.product__container {
		background: #ffffff;
		min-width: 263px;
		border: 1px solid #e3e3e3;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 20px;
		width: fit-content;
		max-width: 270px;
	}
	@media (max-width: 1199px) {
		margin: 0 5px;
	}
	.image__container {
		min-height: 200px;
		.img-responsive {
			// max-width: 52px;
			max-height: 200px;
		}
	}
	.product__cta__title {
		letter-spacing: 0.15px;
		color: #212121 !important;
		cursor: pointer;
		min-height: 70px;
		z-index: 10;
	}

	.product__cta {
		z-index: 10;
	}

	.product__price {
		color: #117b53;
	}
	@media (max-width: 579px) {
		.product__container {
			width: auto;
			max-width: inherit;
		}
	}
`;

const StyledNextPrev = styled.div`
	.button-prev-next {
		background-color: transparent;
		border: none;
		position: absolute;
		top: 40%;
	}
	.button-next {
		right: -35px;
		z-index: 10;
	}
	.button-prev {
		left: -20px;
		z-index: 10;
	}

	@media (min-width: 1230px) {
		.button-next {
			right: -45px;
			display: ${({ products }) => (products.length > 4 ? 'block' : 'none')};
		}
		.button-prev {
			left: -35px;
			display: ${({ products }) => (products.length > 4 ? 'block' : 'none')};
		}
	}

	@media (max-width: 1199px) {
		.button-next {
			display: ${({ products }) => (products.length > 3 ? 'block' : 'none')};
		}
		.button-prev {
			display: ${({ products }) => (products.length > 3 ? 'block' : 'none')};
		}
	}

	@media (max-width: 991px) {
		.button-next {
			display: ${({ products }) => (products.length > 2 ? 'block' : 'none')};
		}
		.button-prev {
			display: ${({ products }) => (products.length > 2 ? 'block' : 'none')};
		}
	}

	@media (max-width: 600px) {
		.button-next {
			right: -25px;
		}
		.button-prev {
			left: -20px;
		}
	}

	@media (max-width: 579px) {
		.button-next {
			display: ${({ products }) => (products.length > 1 ? 'block' : 'none')};
		}
		.button-prev {
			display: ${({ products }) => (products.length > 1 ? 'block' : 'none')};
		}
	}

	@media (max-width: 450px) {
		.button-next {
			display: none;
		}
		.button-prev {
			display: none;
		}
	}
`;

const ProductCarousel = ({ title, isShowPricing, isEnableAddToCart }) => {
	const { products, sectionTitle, section, description } = useContext(
		ProductCarouselContext
	);

	const brandData = useBrandDetails();

	let sliderRef = useRef(null);
	const next = () => {
		sliderRef.slickNext();
	};
	const previous = () => {
		sliderRef.slickPrev();
	};

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: products?.length > 4 ? 4 : products?.length || 4,
		slidesToScroll: products?.length > 4 ? 4 : 1,
		centerPadding: '20px',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					adaptiveHeight: true,
					centerMode: false,
					arrows: false,
					dots: false
				}
			},
			{
				breakpoint: 579,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: true,
					centerMode: false,
					arrows: false,
					dots: false
				}
			},
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: true,
					centerMode: false,
					arrows: false,
					dots: true
				}
			}
		]
	};

	const pageTitle = title || sectionTitle;

	return (
		<div className={`container ${brandData.tag}`}>
			<StyledMain products={products || []}>
				<section className='homepage__section homepage__recommender'>
					{pageTitle ? (
						section !== 'Unlimited' ? (
							<h4 className='text-center mb-20'>{pageTitle}</h4>
						) : (
							<div className='d-flex justify-content-center align-items-center mb-20'>
								<img
									src='/images/au/wpe/unlimited/logo_UNL_2022_v2.png'
									alt='unlimited'
									className='d-none d-lg-block'
									style={{ width: '15%' }}
								/>
								<h4 className='text-center' style={{ padding: '0px 10px' }}>
									{pageTitle}
								</h4>
								<img
									src='/images/au/wpe/unlimited/logo_UNL_2022_v2.png'
									alt='unlimited'
									className='d-none d-lg-block'
									style={{ width: '15%' }}
								/>
							</div>
						)
					) : null}
					{description && (
						<div dangerouslySetInnerHTML={{ __html: description }}></div>
					)}
					<div className={`${brandData.tag}`} style={{ position: 'relative' }}>
						<Slider
							{...settings}
							ref={(slider) => {
								sliderRef = slider;
							}}
						>
							{products.map((product, index) => {
								return (
									<StyledLayout key={`${product.itemCode}_${index}`}>
										<div className='product__container d-flex align-items-center flex-column'>
											<ProductImage
												productName={product.name}
												productVintage={product.vintage}
												prodImage={product.largeImage}
												itemCode={product.itemCode}
											/>
											<a
												className='product__cta__title text_big text-center mb-12 d-flex align-items-center text-decoration-none font-weight-bold'
												target='_blank'
												rel='noreferrer'
												href={`/product/${product.name.split(' ').join('-')}${
													product.vintage != '' ? '-' + product.vintage : ''
												}/${product.itemCode}`}
											>
												{product.name} {product.vintage}
											</a>
											{isShowPricing ? (
												<ProductPrice product={product} />
											) : null}
											{isEnableAddToCart ? (
												<AddtoCartProduct itemCode={product.itemCode} />
											) : null}
											{!isEnableAddToCart && (
												<a
													className='product__cta btn btn-primary mb-12 d-flex align-items-center font-weight-bold '
													target='_blank'
													rel='noreferrer'
													href={`/product/${product.name.split(' ').join('-')}${
														product.vintage != '' ? '-' + product.vintage : ''
													}/${product.itemCode}`}
												>
													View wine
												</a>
											)}
											{!isShowPricing && (
												<span className='product__price font-weight-bold'>
													From $
													{product?.isMixed
														? product?.lowestPricePerBottle?.toFixed(2)
														: product?.skus[0]?.salePrice.toFixed(2)}{' '}
													a bottle
												</span>
											)}
										</div>
									</StyledLayout>
								);
							})}
						</Slider>

						<StyledNextPrev products={products || []}>
							<button
								className='button-prev button-prev-next'
								onClick={previous}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									fill='gray'
								>
									<path d='M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z' />
								</svg>
							</button>

							<button className='button-next button-prev-next' onClick={next}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									fill='gray'
								>
									<path d='M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z' />
								</svg>
							</button>
						</StyledNextPrev>
					</div>
				</section>
			</StyledMain>
		</div>
	);
};

export default ProductCarousel;
